
import {
	createRouter,
	createWebHistory
} from "vue-router";

import HomeView from "../views/Home.vue";
import AboutView from "../views/About.vue";
import ServicesView from "../views/Services.vue";




import ErrorPage from "../views/Error/404-Page.vue";


// هذا للغة 
import store from '../store';
var langV = store.state['lang'];


const routes = [{
	path: "/",
	name: (langV == "en") ? 'Home' : 'الصفحة الرئيسية',
	component: HomeView,
},
{
	path: "/about-us",
	name: (langV == "en") ? 'About us' : 'حولنا',
	component: AboutView,
}
	,
{
	path: "/services",
	name: (langV == "en") ? 'Services' : 'خدماتنا',
	component: ServicesView,
}
	,
{
	path: "/service-1",
	name: (langV == "en") ? 'Facilities rehabilitation services and new project development' : 'خدمات تأهيل المنشآت وتنمية المشاريع الجديدة',
	component: () => import("../views/Ser1.vue"),
},
{
	path: "/service-2",
	name: (langV == "en") ? 'Establishing and managing the necessary administrative departments' :'تأسيس وإدارة الأقسام الإدارية اللازمة',
	component: () => import("../views/Ser2.vue"),
},
{
	path: "/service-3",
	name: (langV == "en") ? 'Support in protocols, projects and temporary issues' :'المساندة في البروتوكولات والمشاريع والقضايا المؤقتة',
	component: () => import("../views/Ser3.vue"),
},
{
	path: "/service-4",
	name: (langV == "en") ? 'Innovative administrative services "on demand"' :'خدمات إدارية مبتكرة "حسب الطلب"',
	component: () => import("../views/Ser4.vue"),
},

// الحالة الأخيرة
// اللي بتمثل 
// 404 page errore
{
	path: "/:catchAll(.*)",
	component: ErrorPage,
},
];

const router = createRouter({
	scrollBehavior(to, from, savedPosition) {
		return { top: 0 }
	},
	history: createWebHistory(process.env.BASE_URL),
	routes,
});


// أسماء الصفحات في مشروعي
router.beforeEach((to, from, next) => {

	if (langV == 'en') {
		if (to.name !== undefined) {
			document.title = `${to.name} | ${process.env.VUE_APP_TITLE}  `;
		} else {
			document.title = `Unknown page | ${process.env.VUE_APP_TITLE}`;
		}
	} else {
		if (to.name !== undefined) {
			document.title = `${to.name} | ${process.env.VUE_APP_TITLE_Ar}  `;
		} else {
			document.title = `صفحة غير معرفة | ${process.env.VUE_APP_TITLE_Ar}`;
		}
	}



	next();

});

export default router;