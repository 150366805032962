import store from '../../store';

$(document).ready(function () {

	console.time();

	// Set Value of attr lang
	// Set All Style 
	var langV = store.state['lang'];
	$('html').attr('lang', langV);

	const bootstrapEn = 'https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.2.1/css/bootstrap.min.css';
	const bootstrapAr = 'https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.2.1/css/bootstrap.rtl.min.css';

	const metaDescriptionEn = 'We are friends and partners with our creative clients, dedicated to creating paths for continued success. We provide innovative solutions, facilitate facility rehabilitation, establish and manage administrative departments, and offer on-demand administrative services. Transforming difficulties into comfort and obstacles into opportunities.';

	const metaDescriptionAr = "نحن أصدقاء وشركاء مع عملائنا الإبداعيين لخلق مسارات للنجاح المستمر. نحن ملتزمون بتقديم أفضل الحلول والخيارات والابتكارات لعملائنا، بالإضافة إلى توفير حلول تتجاوز التوقعات ونسعى دائمًا لتحويل الصعوبات إلى راحة والمعوقات إلى فرص.";

	const metaKeywordsEn = 'sustainable tracks, facility rehabilitation, new project development, administrative departments, innovative administrative services, sustainability, success, challenges, opportunities';

	const metaKeywordsAr = "المسارات المستدامة،  تطوير مشاريع جديدة، الإدارة الإدارية، الخدمات الإدارية المبتكرة، الاستدامة، النجاح، التحديات، الفرص"


	if (langV == 'en') {
		$('.bootstrap-lang').attr('href', bootstrapEn);
		$('.meta-description').attr('content', metaDescriptionEn);
		$('.meta-keywords').attr('content', metaKeywordsEn);
	} else {
		$('.bootstrap-lang').attr('href', bootstrapAr);
		$('.meta-description').attr('content', metaDescriptionAr);
		$('.meta-keywords').attr('content', metaKeywordsAr);
	}


	// Close Nav When Click On it
	var WinWidth = $(window).width()
	if (WinWidth < 992) {
		$(window).on("scroll", function () {
			$('#nav-container').collapse('hide');
		})
	}


	// Up Arrow Click
	$(window).on("scroll", function () {
		if ($(window).scrollTop() > 25) {
			$("#up-arrow").css({
				"opacity": "1",
				"transform": "translateX(0%)"
			})

		} else {
			$("#up-arrow").css({
				"opacity": "0",
				"transform": "translateX(-200%)"
			})
		}

	})



	// Enable Plugin Animation On Scroll (AOS)
	AOS.init({
		duration: 1000,
		delay: 250,
		offset: 0
	})




	console.timeEnd();

}); //End ready() ==> End Code JQuery