<template>
  <!-- Footer -->
  <footer>
    <div class="container" data-aoss="zoom-in-up">
      <div class="row g-0">
        <div class="col-12 col-lg-8">
          <p class="text-center text-lg-start">
            © <span id="year"></span>,
            {{ $t("name company") }}, {{ $t("All Rights Reserved.") }}

            <span class="d-none d-lg-inline-block">&nbsp;|&nbsp;</span>

            <span class="d-none d-lg-inline-block m-2 m-lg-0">
              {{ $t("Programming and development by") }}

              <a
                href="https://wa.me/963950556805"
                class="fw-bold mx-1 text-black"
              >
                {{ $t(" Mohammad Mousa") }}</a
              >
            </span>
          </p>
        </div>
    
        <div class="website text-center text-lg-end col-12 col-lg-4">
          <a href="tel:+966505300601" class="me-2"
            ><img src="https://img.icons8.com/color/25/000000/blackberry.png"
          /></a>

          <a href="mailto:Sustainable@gmail.com" class="me-2"
            ><img src="https://img.icons8.com/color/27/000000/gmail-new.png"
          /></a>

          <a href="https://wa.me/966505300601" class="me-1"
            ><img src="https://img.icons8.com/color/31/000000/whatsapp--v5.png"
          /></a>

          <a href="#" class="d-none"
            ><img src="https://img.icons8.com/fluency/28/000000/twitter.png"
          /></a>
        </div>

        <span class="mx-auto d-block d-lg-none m-2 m-lg-0 w-100 text-center">
          {{ $t("Programming and development by") }}

          <a href="https://wa.me/963950556805" class="fw-bold mx-1 text-black">
            {{ $t(" Mohammad Mousa") }}</a
          >
        </span>
      </div>
    </div>
  </footer>
  <!-- End Footer -->
</template>

<script>
export default {
  name: "Footer",
  mounted() {
    var year = new Date().getFullYear();
    $("#year").text(year);
  },
};
</script>

<style lang="scss" scoped>
</style>