<template>
  <!-- Up Arrow -->
  <span id="up-arrow" class="up-arrow" @click="toTop()">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 172 172"
      style="fill: #000000"
    >
      <g
        fill="none"
        fill-rule="nonzero"
        stroke="none"
        stroke-width="1"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-miterlimit="10"
        stroke-dasharray=""
        stroke-dashoffset="0"
        font-family="none"
        font-weight="none"
        font-size="none"
        text-anchor="none"
        style="mix-blend-mode: normal"
      >
        <path d="M0,172v-172h172v172z" fill="none"></path>
        <g fill="#eee">
          <path
            d="M86.20156,11.25391c-1.4663,0 -2.93278,0.57002 -4.05364,1.69089l-40.53646,40.53646c-1.09507,1.09507 -1.67969,2.56298 -1.67969,4.05364c0,0.7396 0.12739,1.48386 0.42552,2.19479c0.88293,2.14427 2.99155,3.53854 5.30781,3.53854h23.33646v86c0,6.33533 5.13133,11.46667 11.46667,11.46667h11.46667c6.33533,0 11.46667,-5.13133 11.46667,-11.46667v-86h23.33646c2.31627,0 4.41368,-1.39427 5.29661,-3.53854c0.8944,-2.14427 0.39676,-4.60297 -1.24297,-6.24844l-40.53646,-40.53646c-1.12087,-1.12087 -2.58735,-1.69089 -4.05365,-1.69089z"
          ></path>
        </g>
      </g>
    </svg>
  </span>
  <!-- End Up Arrow -->
</template>

<script>
export default {
  name: "UpArrow",
  methods: {
    toTop() {
      $("body, html").animate(
        {
          scrollTop: 0,
        }, 0 
   
      );
    },
  },
};
</script>

<style lang="scss" scoped>
</style>