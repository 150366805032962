<template>
  <section class="f-sec contact-us pt-3 pb-5" id="contactUs">
    <div class="container">
      <h2 data-aos="zoom-in">
        <span class="fl">{{ $t("CONTACT US") }}</span>
      </h2>

      <p data-aos="zoom-in">
        {{
          $t(
            "Our specialized team will always be ready to have the honor of communicating with you through our communication channels."
          )
        }}

        <br />
        <br />
      </p>

      <div class="row contact-us-info">
        <section class="col-12 col-sm-6 col-lg-4">
          <section data-aos="zoom-in" class="content">
            <img src="https://img.icons8.com/bubbles/100/000000/phone.png" />
            <h4>{{$t('Phone')}}:</h4>
            <div>
              <p dir="ltr" style="direction:ltr">+966 50 530 0601</p>
    
            </div>
          </section>
        </section>
        <section class="col-12 col-sm-6 col-lg-4">
          <section data-aos="zoom-in" class="content">
            <img src="https://img.icons8.com/bubbles/100/000000/email.png" />
          
						<h4>{{$t('Email')}}:</h4>
            <div>
              <p>Sustainable@gmail.com</p>
            </div>
          </section>
        </section>
        <section class="col-12 col-lg-4">
          <section data-aos="zoom-in" class="content">
            <img src="https://img.icons8.com/bubbles/100/000000/address.png" />
        
							<h4>{{$t('Address')}}:</h4>
            <div>
              <p>{{$t('Address1')}}</p>
            </div>
          </section>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
import {  mapState } from "vuex";

export default {
  name: "ContactUsViewSection",
  computed: {
    ...mapState(["lang", "fadeStart", "fadeEnd"]),
  },

};
</script>

<style lang="scss" scoped>
</style>
