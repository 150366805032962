<template>
  <Nav />
  <section class="page_404 f-sec">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="col-sm-10 mx-auto col-sm-offset-1 text-center">
            <div class="four_zero_four_bg">
              <h1 class="text-center">404</h1>
            </div>

            <div class="contant_box_404">
              <h3 class="h2" data-aos="zoom-in">
                {{$t('Look like you\'re lost')}}
              </h3>

              <p>
                {{ $t("the page you are looking for not avaible!") }}
              </p>

              <a
                href="#"
                @click="homePage()"
                class="main_button link_404 my-1 d-inline-block back-to-home"
                >{{ $t("Go to Home") }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Footer />
</template>

<script>
import Nav from "@/components/Layout/Nav.vue";
import Footer from "@/components/Layout/Footer.vue";

export default {
  name: "ErrorPage",
  data() {
    return {};
  },
  components: {
    Nav,
    Footer,
  },

  methods: {
    homePage() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>>

