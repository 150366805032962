<template>
  <section class="f-sec services pt-3 pb-5" id="services">
    <div class="container">
      <h2 data-aos="zoom-in">
        <span class="fl">{{ $t("SERVICES") }}</span>
      </h2>

      <p data-aos="zoom-in" class="mb-3">
        {{ $t("We Offer Integrated Services And Solutions.") }}
      </p>

      <div class="row all-services">
        <div class="service-container col-6 col-md-3">
          <router-link
            tag="a"
            class="service"
            to="/service-1"
            data-aos="zoom-in"
          >
            <figure><img src="@/assets/img/ser-1.jpg" /></figure>
            <section>
              <h3>
                {{
                  $t(
                    "Facilities rehabilitation services and new project development."
                  )
                }}
              </h3>
              <button>
                {{ $t("Read More") }}
              </button>
            </section>
          </router-link>
        </div>

        <div class="service-container col-6 col-md-3">
          <router-link
            tag="a"
            class="service"
            to="/service-2"
            data-aos="zoom-in"
          >
            <figure><img src="@/assets/img/ser-2.jpg" /></figure>
            <section>
              <h3>
                {{
                  $t(
                    "Establishing and managing the necessary administrative departments."
                  )
                }}
              </h3>
              <button>
                {{ $t("Read More") }}
              </button>
            </section>
          </router-link>
        </div>
        <div class="service-container col-6 col-md-3">
          <router-link
            tag="a"
            class="service"
            to="/service-3"
            data-aos="zoom-in"
          >
            <figure><img src="@/assets/img/ser-3.jpg" /></figure>
            <section>
              <h3>
                {{ $t("Support in protocols, projects and temporary issues.") }}
              </h3>
              <button>
                {{ $t("Read More") }}
              </button>
            </section>
          </router-link>
        </div>
        <div class="service-container col-6 col-md-3">
          <router-link
            tag="a"
            class="service"
            to="/service-4"
            data-aos="zoom-in"
          >
            <figure><img src="@/assets/img/ser-4.jpg" /></figure>
            <section>
              <h3>
                {{ $t('Innovative administrative services "on demand".') }}
              </h3>
              <button>
                {{ $t("Read More") }}
              </button>
            </section>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {  mapState } from "vuex";

export default {
  name: "ServicesViewSection",
  computed: {
    ...mapState(["lang", "fadeStart", "fadeEnd"]),
  },

};
</script>

<style lang="scss" scoped>
</style>
