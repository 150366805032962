<template>
  <Nav />
  <ServicesViewSection />
  <ContactUsViewSection />
  <UpArrow />
  <Footer />
</template>

<script>
import { mapState } from "vuex";

import Nav from "@/components/Layout/Nav.vue";
import ServicesViewSection from "@/components/sections/Services.vue";
import ContactUsViewSection from "@/components/sections/ContactUs.vue";
import UpArrow from "@/components/Layout/UpArrow.vue";
import Footer from "@/components/Layout/Footer.vue";

export default {
  name: "ServicesView",
  components: {
    Nav,
    ServicesViewSection,
    ContactUsViewSection,
    UpArrow,
    Footer,
  },
  computed: {
    ...mapState(["lang", "fadeStart", "fadeEnd"]),
  },
 
};
</script>

<style lang="scss" scoped>
</style>
