<template>
  <Nav />

  <header class="header" id="home" dir="rtl">
    <!-- <div class="background d-none">
      <img src="../assets/img/12.png" />
    </div> -->
    <div
      class="container d-flex justify-content-center align-items-center align-content-center"
      data-aos="zoom-out-up"
    >
      <div class="text">
        <h1>
          {{ $t("name company") }}
        </h1>
        <h4 class="mt-4 w-75 mx-auto">
          {{
            $t(
              "We friends and partners with our creative clients to create paths of continued success."
            )
          }}
        </h4>

        <a href="#aboutUs" style="display: block; text-align: center">
          <div class="read-more-btn">{{ $t("Read More") }}</div>
        </a>
      </div>
    </div>
  </header>

  <AboutViewSection />
	
  <ServicesViewSection />

  <ContactUsViewSection />


  <UpArrow />
  <Footer />
</template>

<script>

import { mapState } from "vuex";


import Nav from "@/components/Layout/Nav.vue";
import AboutViewSection from "@/components/sections/About.vue";
import ServicesViewSection from "@/components/sections/Services.vue";
import ContactUsViewSection from "@/components/sections/ContactUs.vue";
import UpArrow from "@/components/Layout/UpArrow.vue";
import Footer from "@/components/Layout/Footer.vue";

export default {
  name: "HomeView",
  components: {
    Nav,
    AboutViewSection,
    ServicesViewSection,
    ContactUsViewSection,
    UpArrow,
    Footer,
  },
	 computed: {
    ...mapState(["lang", "fadeStart", "fadeEnd"]),
  },
 
};
</script>

<style lang="scss" scoped>
</style>
