import store from '../../store';

import en from './en.json';
import ar from './ar.json';

export const defaultLocale = store.state['lang'];
// export const defaultLocale ='en';

	export const languages = {
		en: en,
		ar: ar,
	}