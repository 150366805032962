<template>
  <router-view />
</template>

<script>
</script>

<style lang="scss">
@import "./assets/sass/main-en.scss";
@import "./assets/sass/main-ar.scss";
</style>
