<template>
  <section class="f-sec about-us  pt-3 pb-5" id="aboutUs">
    <div class="container">
      <h2 data-aos="zoom-in">
        <span class="fl">{{ $t("aboutUs1") }}</span>&nbsp;<span class="fl">{{ $t("aboutUs2") }}</span>
      </h2>

      <p data-aos="zoom-in">
        {{
          $t(
            "We friends and partners with our creative clients to create paths of continued success."
          )
        }}
        <br />
        {{
          $t(
            "With you, we will always be committed to providing the best options, solutions and innovations to our customers, as well as providing solutions that exceed expectations, and we always strive to transform difficulty into comfort and obstacles into opportunities."
          )
        }}
        <br />
      </p>

      <div class="row my-md-5 my-3 f-row">
        <div class="col-12 col-lg-6 img-cont">
          <img src="@/assets/img/13.jpg" alt="" :data-aos="fadeEnd" />
        </div>

        <div class="col-12 col-lg-6 text-cont py-3">
          <p :data-aos="fadeStart">
            <span>{{ $t("name company") }}</span>&nbsp;
            {{
              $t(
                "We specialize in new project development, assisting in the planning and execution of sustainable projects, we provide innovative administrative services that are tailored to meet your specific needs."
              )
            }}
            <br />
            {{
              $t(
                "Our \"on-demand\" services ensure that you have access to efficient and effective administrative support whenever you need it."
              )
            }}
          </p>

          <p :data-aos="fadeStart">
            <span>{{ $t("name company") }} </span>&nbsp;
            {{
              $t(
                "We are committed to creating a sustainable future and we strive to transform difficulties into comfort and obstacles into opportunities."
              )
            }}
            <br />
            {{
              $t(
                "Partner with us to achieve continued success on your path towards sustainability."
              )
            }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";


export default {
  name: "AboutViewSection",
  computed: {
    ...mapState(["lang", "fadeStart", "fadeEnd"]),
  },

};
</script>

<style lang="scss" scoped></style>
