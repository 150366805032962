import { createStore } from "vuex";

export default createStore({
	state: {

		lang: localStorage.getItem("lang") || "ar",

		fadeStart: localStorage.getItem("lang") == 'en' ? 'fade-left' : 'fade-right',
		fadeEnd: localStorage.getItem("lang") == 'en' ? 'fade-right' : 'fade-left',

	},
	getters: {},
	mutations: {

		arabic(state) {
			$("body").css("overflow-y", "hidden")
			$("#loadingDiv").show();
			window.location.reload();
			localStorage.setItem("lang", "ar");
			state.lang = "Ar";
		},
		english(state) {
			$("body").css("overflow-y", "hidden")
			$("#loadingDiv").show();
			window.location.reload();
			localStorage.setItem("lang", "en");
			state.lang = "En";
		},

	},
	actions: {},
	modules: {},
});
