<template>
  <nav class="navbar navbar-expand-lg navbar-dark sticky-top" id="navv">
    <div class="container">
      <span :data-aoss="fadeEnd">
        <router-link tag="a" class="navbar-brand" to="/" exact>
          <img
            v-if="lang == 'en'"
            src="../../assets/img/logo.png"
            alt=""
            width="200"
          />
          <img
            v-else
            src="../../assets/img/logo-arabic.png"
            alt=""
            width="200"
          />
        </router-link>
      </span>

      <div
        class="dropdown btn-lang d-flex d-lg-none ms-auto"
        :data-aoss="fadeStart"
      >
        <a
          class="lang dropdown-toggle"
          type="button"
          id="lang-dropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {{ lang }}
        </a>
        <ul
          class="dropdown-menu dropdown-menu-end text-center overflow-hidden"
          aria-labelledby="lang-dropdown"
        >
          <li><a class="dropdown-item" href="" @click="arabic()">Ar</a></li>
          <li><a class="dropdown-item" href="" @click="english()">En</a></li>
        </ul>
      </div>

      <button
        :data-aoss="fadeStart"
        class="ms-2 navbar-toggler p-0"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#nav-container"
        aria-controls="nav-container"
        aria-expanded="false"
        aria-label="Toggle Navigation"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="45"
          height="45"
          viewBox="0 0 172 172"
          class="hvr-sink"
          style="fill: #326295"
        >
          <g
            fill="none"
            fill-rule="nonzero"
            stroke="none"
            stroke-width="1"
            stroke-linecap="butt"
            stroke-linejoin="miter"
            stroke-miterlimit="10"
            stroke-dasharray=""
            stroke-dashoffset="0"
            font-family="none"
            font-weight="none"
            font-size="none"
            text-anchor="none"
            style="mix-blend-mode: normal"
          >
            <path d="M0,172v-172h172v172z" fill="none"></path>
            <g fill="#326295">
              <path
                d="M35.83333,21.5c-7.91917,0 -14.33333,6.41417 -14.33333,14.33333v100.33333c0,7.91917 6.41417,14.33333 14.33333,14.33333h65.38184l-14.33333,-14.33333h-51.0485v-71.66667h100.33333v35.83333h14.33333v-64.5c0,-7.91917 -6.41417,-14.33333 -14.33333,-14.33333zM35.83333,35.83333h100.33333v14.33333h-100.33333zM50.16667,78.83333v14.33333h14.33333v-14.33333zM78.83333,78.83333v14.33333h43v-14.33333zM50.16667,107.5v14.33333h14.33333v-14.33333zM78.83333,107.5v14.33333h43v-14.33333zM136.16667,114.66667v28.66667h-21.5l28.66667,28.66667l28.66667,-28.66667h-21.5v-28.66667z"
              ></path>
            </g>
          </g>
        </svg>
      </button>

      <div class="collapse navbar-collapse" id="nav-container">
        <ul class="navbar-nav">
          <li class="nav-item" @click="closeCollapse" data-aoss="zoom-in">
            <router-link tag="a" class="nav-link" to="/" exact>
              {{ $t("Home") }}
            </router-link>
          </li>

          <li class="nav-item" @click="closeCollapse" data-aoss="zoom-in">
            <router-link tag="a" class="nav-link" to="/about-us">
              {{ $t("ABOUT US") }}
            </router-link>
          </li>

          <li class="nav-item" @click="closeCollapse" data-aoss="zoom-in">
            <router-link tag="a" class="nav-link" to="/services">
              {{ $t("SERVICES") }}
            </router-link>
          </li>
<!-- 
          <li class="nav-item" data-aoss="zoom-in">
            <router-link tag="a" class="nav-link" to="/contact-us">
              {{ $t("CONTACT US") }}
            </router-link>
          </li> -->
        </ul>
      </div>

      <div :data-aoss="fadeStart" class="dropdown btn-lang d-none d-lg-flex">
        <a
          class="lang dropdown-toggle"
          type="button"
          id="lang-dropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {{ lang }}
        </a>
        <ul
          class="dropdown-menu dropdown-menu-end text-center overflow-hidden"
          aria-labelledby="lang-dropdown"
        >
          <li><a class="dropdown-item" href="" @click="arabic()">Ar</a></li>
          <li><a class="dropdown-item" href="" @click="english()">En</a></li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "Nav",

  data() {
    return {};
  },
  mounted() {
    var section = this.$router.currentRoute.value.hash.replace("#", "");
    if (section)
      this.$nextTick(() =>
        window.document.getElementById(section).scrollIntoView()
      );
  },
  computed: {
    ...mapState(["lang", "fadeStart", "fadeEnd"]),
  },
  methods: {
    ...mapMutations(["arabic", "english"]),

		closeCollapse(){
			$('#nav-container').collapse('hide');
		},
  },
};
</script>

<style lang="scss" scoped>
</style>